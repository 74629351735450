

import "./landing.css";
import LOGO_HOTEL from "../assets/images/logo-hotel.png";
import GIF_FILE from "../assets/images/TheInvitation.gif";
const LandingComponent = () => {



    return (

        <>

                    <div className="coming-soon-control">
                        <span className="coming-soon-text">Coming soon</span>
                       



                    </div>


        </>

    )
}

export default LandingComponent;